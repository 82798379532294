import { useState, useEffect } from 'react'
import { NavLink, Link, Outlet, useMatch } from 'react-router-dom'
import { ROUTE_MAP, SOCIAL_URL } from 'constants/index'
import { ReactComponent as Logo } from 'logo.svg'
import { ReactComponent as FBIcon } from 'assets/footer-fb-icon.svg'
import { ReactComponent as IGIcon } from 'assets/footer-ig-icon.svg'
import { ReactComponent as YTIcon } from 'assets/footer-yt-icon.svg'
import { ReactComponent as MenuIcon } from 'assets/mini-menu-icon.svg'
import { ReactComponent as CrossIcon } from 'assets/mini-cross-icon.svg'
import lineIconColor from 'assets/line-icon-color.png'
import igIconColor from 'assets/ig-icon-color.png'
import goTop from 'assets/go-top-icon.png'
import styles from './NavWrapper.module.scss'
import classNames from 'classnames'

const NavWrapper = () => {
  const [showGoTop, setShowGoTop] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const routes = Object.keys(ROUTE_MAP)
  const isMatchSub = useMatch(':main/:sub')

  const handleMenuIconClick = () => {
    setMenuOpen(prev => !prev)
  }
  const handleMenuClose = (e) => {
    if (e.target === e.currentTarget) setMenuOpen(false)
  }
  const handleGoTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const handleOnScroll = () => {
    if (window.scrollY >= 450) {
      setShowGoTop(true)
    } else {
      setShowGoTop(false)
    }
  }
  const renderActiveClass = ({ isActive }) =>
    `${isActive ? isMatchSub ? 'active' : 'active disabled' : ''}`

  useEffect(() => {
    document.body.style = menuOpen && `overflow: hidden`
    window.addEventListener('scroll', handleOnScroll)
    return () => {
      window.removeEventListener('scroll', handleOnScroll)
    }
  }, [menuOpen])

  const renderMobileSocial = (Tag = 'div') => (
    <Tag className={styles.mobileSocial}>
      <Link to={SOCIAL_URL.LINE} target='_blank' rel='noopener'>
        <img src={lineIconColor} alt="colored line icon" />
      </Link>
      <Link to={SOCIAL_URL.INSTAGRAM} target='_blank' rel='noopener'>
        <img src={igIconColor} alt="colored ig icon" />
      </Link>
    </Tag>
  )
  return (
    <div
      className={classNames(styles.container, menuOpen && styles.open)}
      onClick={handleMenuClose}
    >
      <nav className={classNames(styles.navbar)}>
        <Link to="/" className={styles.logo} onClick={menuOpen && handleMenuClose}>
          <Logo />
        </Link>
        <ul>
          {routes.map(routeKey => (
            <li key={routeKey}>
              <NavLink
                to={`/${ROUTE_MAP[routeKey].key}`}
                className={renderActiveClass}
                onClick={menuOpen && handleMenuClose}
              >
                {ROUTE_MAP[routeKey].title}
              </NavLink>
            </li>
          ))}
          {renderMobileSocial('li')}
        </ul>
        <button className={styles.menuButton} onClick={handleMenuIconClick}>
          {menuOpen ? <CrossIcon /> : <MenuIcon />}
        </button>
      </nav>
      <Outlet />
      <div className={classNames(styles.goTop, showGoTop && styles.show)} onClick={handleGoTop}>
        <img src={goTop} alt='go top button' />
      </div>
      <footer className={styles.footer}>
        <Link to="/" className={styles.logo}><Logo /></Link>
        <div className={styles.social}>
          <Link to={SOCIAL_URL.INSTAGRAM} target='_blank' rel='noopener'>
            <IGIcon />
          </Link>
          <Link to={SOCIAL_URL.FB} target='_blank' rel='noopener'>
            <FBIcon />
          </Link>
          <Link to={SOCIAL_URL.YOUTUBE} target='_blank' rel='noopener'>
            <YTIcon />
          </Link>
        </div>
        <p>Copyright © 2024 liyaodata All rights reserved.</p>
      </footer>
    </div>
  )
}

export default NavWrapper
