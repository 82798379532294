import { Suspense } from 'react'
import { RouterProvider } from "react-router-dom"
import Routes from './Routes'

const App = () => {
  return (
    <Suspense fallback={<div />}>
      <RouterProvider router={Routes} />
    </Suspense>
  )
}

export default App
