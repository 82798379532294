import { lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { IG_NEWS_FAKE_DATA } from 'constants/data'
import { ROUTE_MAP } from 'constants/index'
import NavWrapper from 'components/NavWrapper/NavWrapper'

const HomePage = lazy(() => import('pages/HomePage/HomePage'))
const CoursePage = lazy(() => import('pages/CoursePage/CoursePage'))
const IgNewsPage = lazy(() => import('pages/IgNewsPage/IgNewsPage'))
const IgContentPage = lazy(() => import('pages/IgNewsPage/IgContentPage'))
const ServicePage = lazy(() => import('pages/ServicePage/ServicePage'))
const AboutPage = lazy(() => import('pages/AboutPage/AboutPage'))
const ContactPage = lazy(() => import('pages/ContactPage/ContactPage'))
const CoopPage = lazy(() => import('pages/CoopPage/CoopPage'))

const Routes = createBrowserRouter([
  {
    path: '/',
    element: <NavWrapper />,
    children: [
      { index: true, element: <HomePage /> },
      { path: `/${ROUTE_MAP.COURSE.key}`, element: <CoursePage /> },
      {
        path: `/${ROUTE_MAP.IG_NEWS.key}`,
        element: <IgNewsPage />,
        children: [
          {
            path: ':igNewsId',
            element: <IgContentPage />,
            loader: ({ params }) => {
              const data = IG_NEWS_FAKE_DATA.find(data => data.id === params.igNewsId)
              const prevId = `0${+data.id - 1}`
              const nextId = `0${+data.id + 1}`
              return {
                ...data,
                hasPrev: data.id !== IG_NEWS_FAKE_DATA[0].id && {
                  id: prevId,
                  title: IG_NEWS_FAKE_DATA[+prevId - 1].title
                },
                hasNext: data.id !== IG_NEWS_FAKE_DATA[IG_NEWS_FAKE_DATA.length - 1].id
                  && data.id !== '03' &&{
                    id: nextId,
                    title: IG_NEWS_FAKE_DATA[+nextId - 1].title
                  }
              }
            },
          }
        ]
      },
      { path: `/${ROUTE_MAP.SERVICE.key}`, element: <ServicePage /> },
      { path: `/${ROUTE_MAP.ABOUT_US.key}`, element: <AboutPage /> },
      {
        path: `/${ROUTE_MAP.COOPERATION.key}`,
        element: <CoopPage />,
        children: [
          { path: ':customerId', element: <IgContentPage /> }
        ]
      },
      { path: `/${ROUTE_MAP.CONTACT.key}`, element: <ContactPage /> },
  ]},
  { path: '*', element: <Navigate to='/' replace /> },
])

export default Routes
