import excelIcon from 'assets/course-excel-icon.png'
import powerBiIcon from 'assets/course-power-bi-icon.png'
import sqlIcon from 'assets/course-sql-icon.png'
import cover01 from 'assets/ig-content-covers/ig-content-cover-01.png'
import cover02 from 'assets/ig-content-covers/ig-content-cover-02.png'
import cover03 from 'assets/ig-content-covers/ig-content-cover-03.png'
import cover04 from 'assets/ig-content-covers/ig-content-cover-04.png'
import cover05 from 'assets/ig-content-covers/ig-content-cover-05.png'
import cover06 from 'assets/ig-content-covers/ig-content-cover-06.png'
import cover07 from 'assets/ig-content-covers/ig-content-cover-07.png'
import cover08 from 'assets/ig-content-covers/ig-content-cover-08.png'
import cover09 from 'assets/ig-content-covers/ig-content-cover-09.png'
import { IG_CATEGORY } from './index'

export const COURSE_FAKE_CAROUSEL = [
  {
    avatar: excelIcon,
    name: 'L小姐',
    date: '2024.03.15',
    ranking: 5,
    comment: '在這個Excel客製化課程中，我學到了許多實用的技能和知識，包括函數的應用、數據分析技巧以及圖表的製作方法。透過實際的操作和練習，我更加熟練地掌握了Excel的各種功能，提升了自己的工作效率和準確性。',
  },
  {
    avatar: sqlIcon,
    name: 'C小姐',
    date: '2024.03.27',
    ranking: 5,
    comment: `
      一位來自非資工背景的女性，參加客製化SQL課程是一次非常寶貴的學習經歷。縱使沒有SQL背景，但透過這個課程，成功地掌握了SQL查詢語言的基礎知識和技能。
      通過實際操作和案例練習，學會了如何使用SQL來提取、處理和分析數據，這對她未來的職業發展具有重要意義。
    `,
  },
  {
    avatar: powerBiIcon,
    name: 'K小姐',
    date: '2024.04.08',
    ranking: 5,
    comment: `
      對PowerBI有些許了解但不太熟悉的人來說，參與澧曜數據這個客製化課程是一次有趣的體驗。透過這個課程，我發現了PowerBI的強大功能和潛力，但也意識到了自己在使用上的侷限性和不足之處，難以完全掌握所有功能和技巧。
      整體而言，這次體驗讓我更清楚地了解到自己在PowerBI上的需求和挑戰，但同時也謝謝澧曜的數據分析師的指導，讓我意識到這是一個需要時間和努力去學習和提高的過程，並不是一蹴而就的。\
      `,
  },
]

export const IG_NEWS_FAKE_DATA = [
  {
    id: '01',
    cover: cover01,
    date: '4/24',
    tagType: IG_CATEGORY.DATA_TOOL,
    tags: ['#excel', '#excel教學', '#excel小技巧', '#建立圖表', '#客製化課程'],
    title: '凍結窗格快速法',
    summary: `做出圖表快速法
      別再慢慢的插入建立圖表了!📊`,
    content: `15秒輕輕鬆鬆鬆就上手🙌
      做出圖表快速法
      別再慢慢的插入建立圖表了!📊
      👋還想學什麼excel 小技巧，歡迎底下留言告訴我們👋`
  },
  {
    id: '02',
    cover: cover02,
    date: '4/23',
    tagType: IG_CATEGORY.PRO_DATA,
    tags: ['#rfm', '#dataanalysis', '#營運數據分析', '#果農數據', '#案例分享'],
    title: '台灣在地果農果樂園',
    summary: `你還在用自己看得懂的方式在記錄銷售資料嗎?`,
    content: `你還在用自己看得懂的方式在記錄銷售資料嗎?
      ❌❌千萬別這麼做阿阿阿❌❌
      我們的分析師在幫客戶做數據分析時
      其實花最多最多的時間就是在協助客戶整理資料
      慣性使用人類看得懂的方式紀錄資料是非常不利於做分析的
      想知道如何靠好好記錄資料，就能自己做數據分析嗎?
      歡迎聯繫我們，直接私訊小盒子也是可以的唷～
      LINE官方：@liyaodata
      🧡資料記錄好分析沒煩惱🧡`
  },
  {
    id: '03',
    cover: cover03,
    date: '4/19',
    tagType: IG_CATEGORY.LIFE_DATA,
    tags: ['#MBTI', '#資料工作者', '#數據分析師', '#課程教學', '#客製化課程'],
    title: 'MBTI探索職能發展',
    summary: `測驗不是絕對而是參考唷～
      隨著時間與個人成長，MBTI的結果也會不同`,
    content: `MBTI測驗不是絕對而是參考唷～
      隨著時間與個人成長，MBTI的結果也會不同
      有興趣的事物就勇敢嘗試吧👌不怕你不會，就怕你不學
      🎈澧曜客製化學習課程〔〔包堂優惠中〕〕🎈
      對數據有興趣，快找澧曜幫您量身訂製學習課程
      LINE官方：@liyaodata`
  },
  {
    id: '04',
    cover: cover04,
    date: '4/17',
    tagType: IG_CATEGORY.DATA_TOOL,
    tags: [],
    title: '',
    summary: `對齊文字快速法
      別再一直按空白鍵對齊了!👆👆`,
    content: ''
  },
  {
    id: '05',
    cover: cover05,
    date: '4/16',
    tagType: IG_CATEGORY.PRO_DATA,
    tags: [],
    title: '',
    summary: `Excel 遇道瓶頸了嗎😩`,
    content: ''
  },
  {
    id: '06',
    cover: cover06,
    date: '4/12',
    tagType: IG_CATEGORY.LIFE_DATA,
    tags: [],
    title: '',
    summary: `改變車隊，不僅僅是改變比賽戰力而已`,
    content: ''
  },
  {
    id: '07',
    cover: cover07,
    date: '3/12',
    tagType: IG_CATEGORY.LIFE_DATA,
    tags: [],
    title: '',
    summary: `Amazon精準的預測式購物，
      將消費者的🧡牢牢抓住`,
    content: ''
  },
  {
    id: '08',
    cover: cover08,
    date: '3/08',
    tagType: IG_CATEGORY.LIFE_DATA,
    tags: [],
    title: '',
    summary: `伴手禮買日本威士忌，值得蒐藏得好選擇`,
    content: ''
  },
  {
    id: '09',
    cover: cover09,
    date: '3/05',
    tagType: IG_CATEGORY.PRO_DATA,
    tags: [],
    title: '',
    summary: `購物籃分析，啟動你的業績之旅！🚀`,
    content: ''
  },
]
