export const ROUTE_MAP = {
  COURSE: {
    key: 'course-content',
    title: '課程內容',
    subtitle: 'Course content',
  },
  IG_NEWS: {
    key: 'ig-news',
    title: 'IG 最新消息',
    subtitle: 'IG NEWS',
  },
  SERVICE: {
    key: 'service',
    title: '服務介紹',
    subtitle: 'SERVICE',
  },
  ABOUT_US: {
    key: 'about-us',
    title: '關於澧曜',
    subtitle: 'ABOUT LIYAO',
  },
  COOPERATION: {
    key: 'cooperation-case',
    title: '合作案例',
    subtitle: 'Cooperation Case',
  },
  CONTACT: {
    key: 'contact-us',
    title: '聯絡我們',
    subtitle: 'CONTACT US',
  },
}

export const SOCIAL_URL = {
  INSTAGRAM: 'https://www.instagram.com/liyaodata',
  FB: 'https://www.facebook.com/profile.php?id=61554476056224',
  YOUTUBE: 'https://www.youtube.com/@user-jj5us8pj7g',
  LINE: 'https://lin.ee/Zm2f3Vy',
  EMAIL: 'LY@liyao.co'
}

export const IG_CATEGORY = {
  DATA_TOOL: { text: '#數據工具教學', color: '#59AD87' },
  PRO_DATA: { text: '#專業數據', color: '#14609A' },
  LIFE_DATA: { text: '#生活數據', color: '#5BB7C2' },
}

export const MEDIA_QUERY = {
  MD: '(max-width: 768px)',
  MD_ONLY: '(min-width: 577px) and (max-width: 768px)',
  SM: '(max-width: 576px)',
  SM_ONLY: '(min-width: 577px) and (max-width: 576px)',
  IPHONE_MAX: '(max-width: 430px)'
}
